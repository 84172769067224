<template>
  <b-card>
    <h6>
      {{ `${$t('fields.result')} (${total})` }}
    </h6>
    <b-row class="my-2">
      <b-col>
        <b-input-group>
          <b-form-input
            v-model="search"
            type="search"
            :placeholder="`${$t('fields.search')}... (เบอร์/ เลขที่บช./ ชื่อ)`"
          />
        </b-input-group>
      </b-col>
      <b-col
        v-if="isOwner"
        cols="3"
      >
        <master-select-input 
          :value="selectedMaster"
          hide-label
          @update="onMasterIdChange"
        />
      </b-col>
      <b-col
        v-if="isOwner || isMaster"
        cols="3"
      >
        <agent-select-input
          :value="selectedAgent"
          :master-id="selectedMaster"
          hide-label
          @update="onAgentIdChange"
        />
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      :items="filteredList"
      :fields="type==='deposit'? depositFields: withdrawFields"
      :busy="isFetching"
      responsive
      show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(transactionDateTime)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(updatedAt)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(createdAt)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(user)="data">
        <b-media vertical-align="center">
          <template #aside>
            <BankIcon :bank-code="data.value.bankCode" />
          </template>
          {{ data.value.bankAccountNumber }}<br>
          {{ data.value.firstname }} {{ data.value.lastname }}
        </b-media>
      </template>
      <template #cell(fromBankCode)="data">
        <b-media vertical-align="center">
          <template #aside>
            <BankIcon :bank-code="data.value" />
          </template>
          {{ data.item.fromAccountNumber }}<br>
          {{ data.item.fromAccountName }}
        </b-media>
      </template>

      <template #cell(agentBankAccount)="data">
        <b-media vertical-align="center">
          <template #aside>
            <BankIcon :bank-code="data.value.bankCode" />
          </template>
          {{ data.value.bankAccountNumber }}<br>
          {{ data.value.bankAccountName }}
        </b-media>
      </template>
      <template #cell(amount)="data">
        <span :class="type==='deposit'?'text-success': 'text-warning'">
          {{ data.value | currency }}
        </span>
      </template>
      <template #cell(afterBalance)="data">
        <b-badge class="badge-soft-danger">
          {{ data.item.beforeBalance | currency }}
        </b-badge><br>
        <b-badge class="badge-soft-success">
          {{ data.value | currency }}
        </b-badge>
      </template>
      <template #cell(remain)="data">
        {{ displayRemainAmount(data.item) }}
      </template>
      <template #cell(status)="data">
        <b-badge
          v-if="data.value === 0"
          variant="success"
        >
          {{ $t('utils.status.success_credit') }}
        </b-badge>
        <b-badge
          v-else-if="data.value === 1"
          variant="dark"
        >
          ฝากมือ
        </b-badge>
        <b-badge
          v-else-if="data.value === 2"
          variant="dark"
        >
          ไม่เจอยูส
        </b-badge>
        <b-badge
          v-else-if="data.value === 4"
          variant="warning"
        >
          ดึงเครดิตคืน
        </b-badge>
        <b-badge
          v-else
          variant="danger"
        >
          {{ $t('utils.status.unsuccessful') }}
        </b-badge>
      </template>
      <template #cell(transactionRemark)="data">
        {{ data.value || '-' }}
      </template>
      <template #cell(transactionChannelCode)="data">
        <b-badge>
          {{ data.item.transactionChannelDescription || '-' }}
          ({{ data.value || '-' }})
        </b-badge>
      </template>
      <template #cell(operation)="data">
        <b-button
          v-if="[0,1].includes(data.item.status)"
          variant="danger"
          @click="openToggleDepositModal(data.item)"
        >
          <feather-icon
            icon="RotateCcwIcon"
          />
          ดึงคืน
        </b-button>
        <b-button
          v-if="[3,4].includes(data.item.status)"
          variant="primary"
          @click="openToggleDepositModal(data.item)"
        >
          <feather-icon
            icon="RotateCcwIcon"
          />
          คืนเครดิต
        </b-button>
      </template>
      <template #empty="">
        <p class="text-center text-muted">
          {{ $t('messages.nothing_here') }}
        </p>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
    </b-table>
    <!-- pagination -->
    <b-row>
      <b-col md="4">
        <page-limit-select
          :value="limit"
          @update="onLimitChange"
        />
      </b-col>
      <b-col>
        <pagination-input
          :per-page="limit"
          :total="total"
          @update="(val) => currentPage = val"
        />
      </b-col>
    </b-row>
    <toggle-deposit-modal
      :txn="selectedTxn"
      :search="search"
    />
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'DoneTransactions',
  components: {
    ToggleDepositModal: () => import('./ToggleDepositModal.vue'),
  },
  props: {
    type: {
      type: String,
      default: 'deposit',
    },
  },
  data() {
    return {
      currentPage: 1,
      search: '',
      filterType: '',
      onSearchTimeout: null,
      selectedTxn: {},
      depositFields: [
        '#',
        {
          key: 'updatedAt',
          label: 'เวลาปรับยอด',
          thStyle: {
            minWidth: '160px',
          },
        },
        {
          key: 'transactionDateTime',
          label: 'เวลาฝาก',
          thStyle: {
            minWidth: '160px',
          },
        },
        {
          key: 'fromBankCode',
          label: 'บัญชีโอน',
          thStyle: {
            minWidth: '220px',
          },
        },
        {
          key: 'agentBankAccount',
          label: 'บัญชีรับ',
          thStyle: {
            minWidth: '220px',
          },
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'afterBalance',
          label: 'คงเหลือ (ก่อน-หลัง)',
          class: 'text-right',
          thStyle: {
            minWidth: '170px',
          },
        },
        {
          key: 'transactionChannelCode',
          label: this.$t('fields.channel'),
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'status',
          label: this.$t('fields.status'),
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'transactionRemark',
          label: this.$t('fields.remark'),
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'operation',
          label: `${this.$t('buttons.operation')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '170px',
          },
        },
      ],
      withdrawFields: [
        '#',
         {
          key: 'user',
          label: 'บัญชี',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'afterBalance',
          label: 'คงเหลือ (ก่อน-หลัง)',
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'createdAt',
          label: 'เวลาแจ้ง',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'transactionDateTime',
          label: 'เวลาโอน',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'remark',
          label: this.$t('fields.remark'),
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
      selectedMaster: '',
      selectedAgent: '',
      selectedLimit: 20,
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.deposit.isFetchingDepositDone  || state.withdraw.isFetchingWithdrawDone,
    }),
    ...mapGetters(['isOwner', 'isMaster', 'depositDone', 'withdrawDone']),
    filteredList() {
      return this.type === 'deposit'? this.depositDone.items || []: this.withdrawDone.items 
    },
    pagination() {
      return this.type === 'deposit'? this.depositDone.meta : this.withdrawDone.meta 
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
    filterType(val){
      if(val){
        this.fetchData()
      }
    },
  },
  methods: {
    ...mapActions(['fetchDepositDone', 'fetchWithdrawDone']),
    fetchData() {
      if(this.type === 'deposit'){
        this.fetchDepositDone({
          limit: this.selectedLimit,
          page: this.currentPage,
          search: this.search,
          masterId: this.selectedMaster,
          agentId: this.selectedAgent,
        })
      } else {
        this.fetchWithdrawDone({
          limit: this.selectedLimit,
          page: this.currentPage,
          search: this.search,
          masterId: this.selectedMaster,
          agentId: this.selectedAgent,
        })
      }
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onLimitChange(limit) {
      if(limit){
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    displayRemainAmount(txn) {
      const remain = (Number(txn.beforeBalance) - Number(txn.afterBalance));
      return remain.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
    openToggleDepositModal(txn) {
      this.selectedTxn = txn
      this.$bvModal.show('toggle-deposit-modal')
    }
  },
}
</script>
